.App {
  text-align: center;
  min-width: 400px;
}

.App-logo {
  height: 10vmin;
  pointer-events: none;
}

.cc-logo {
  width: 80%;
  max-width: 450px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: white;
  min-height: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
  background-image: url(/src/images/header-full-bg.svg);
  background-size: cover;
  background-position: center 280px;
  background-repeat: no-repeat;
  height: 100vh;
}

.App-section {
  position: relative;
  height: 100vh;
  width: 100vw;
  background-repeat: no-repeat;
}

.About-section {
  background-image: url(/src/images/about-cc-full-bg.svg);
  background-size: 100%;
  background-position: center 200px;
}

.slogan {
  position: relative;
  color: black;
  font-size: 20px;
  margin-top: 20px;
  width: 60%;
  max-width: 800px;
}

.short-description {
  font-size: 15px;
  color: rgb(107, 107, 107);
  width: 60%;
  max-width: 800px;
  margin-top: 20px;
  text-align: left;
}

.boxed-description {
  position: absolute;
  bottom: 180px;
  left: 50%;
  transform: translate(-50%, 0%);
  margin: auto auto 10px auto;
  width: 50vw;
  text-align: left;
  font-size: 18px;
  box-shadow: 0px 2px 5px 2px rgb(241, 241, 241);
  padding: 20px;
  border-radius: 5px;
  background-color: white;
}

.Contact-section {
  background-image: url(/src/images/connect-bg.svg);
  background-size: 100%;
  background-position: center 220px;
  margin-top: 100px;
  height: 70vh;
}

.contact-section-title {
  margin: 50px auto 0 auto;
  position: relative;
  max-width: 450px;
  height: 220px;
  background-image: url(/src/images/connect-bg-detail.svg);
  background-size: 100%;
  background-position: center bottom;
  background-repeat: no-repeat;
}

.contact-buttons-holder {
  width: 50%;
  height: 100px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0 auto 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contact-button {
  /* width: 100px;
  height: 100px; */
  position: relative;
  display: flex;
  /* margin: auto; */
}

.email-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.email-text {
  width: fit-content;
  margin: auto 0;
  font-size: 16px;
}

a {
  color: black;
}

.email-icon {
  width: 50px;
  height: 50px;
  min-width: 50px;
  min-height: 50px;
  background-image: url(/src/images/email-icon.svg);
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 10px;
}

.App-link {
  color: #61dafb;
}

.footer {
  width: 100%;
  height: 50px;
}

.copyright-text {
  font-size: 16px;
  color: rgb(105, 105, 105);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  .App-header {
    background-position: center 60px;
    height: max-content;
    margin-top: 100px;
  }

  .slogan {
    font-size: 16px;
  }

  .Contact-section {
    width: 100%;
    background-size: 150%;
  }

  .contact-section-title {
    background-size: 80%;
  }

  .contact-buttons-holder {
    width: 100%;
  }
  .contact-button {
    width: 100%;
  }

  .email-button {
    flex-direction: column;
  }

  .email-icon {
    margin: 0 auto;
  }

  .email-text {
    margin: auto;
    font-size: 14px;
  }

  .copyright-text {
    font-size: 12px;
    padding: 0 20px;
  }
}
